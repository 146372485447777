.container__3jZG0 {
  background: #3c4761;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 13.125rem; }
  @media screen and (max-width: 25.875rem) {
    .container__3jZG0 {
      padding-top: 5.625rem; } }

.box__p_gzg {
  padding: 0 2rem;
  background: #ffffff;
  box-shadow: 0px 12px 7px rgba(79, 88, 122, 0.06);
  border-radius: 4px;
  width: 25rem; }
  @media screen and (max-width: 25.875rem) {
    .box__p_gzg {
      width: none; } }

.content__2fdxn {
  text-align: center; }

.pictogram__2O0Tr {
  width: 2.5rem;
  height: auto;
  margin-top: 2.8125rem;
  margin-bottom: 1.25rem; }

.heading__3WHIG {
  margin-bottom: 1.25rem;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.073082px;
  color: #3c4666; }

.description__3vIf9 {
  max-width: 75%;
  margin: auto;
  margin-bottom: 3.125rem;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.0578231px;
  color: #acb2c7; }

.buttons__WgBni {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.875rem; }

.button__2BCUT {
  margin-bottom: 0.625rem; }

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 0;
  cursor: pointer; }

.cancel__2ELXq {
  position: relative; }
  .cancel__2ELXq button {
    position: absolute;
    margin: 0;
    padding: 0; }
  .cancel__2ELXq svg {
    width: 0.875rem;
    height: auto;
    position: absolute;
    left: 0;
    top: 2rem; }
