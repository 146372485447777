.sendMessage__ORk7b {
  width: 100%; }
  .sendMessage__ORk7b label {
    display: none; }
  .sendMessage__ORk7b textarea {
    border-radius: 4px;
    border: none;
    background: #f3f6fd;
    width: 100%;
    resize: none;
    padding: 1rem;
    font-family: MaisonNeue;
    font-size: 0.875rem;
    line-height: 1.5625rem;
    padding-right: 6.25rem;
    height: 70px; }
