.container__CNJmP {
  width: 100%;
  top: 0; }

.topBar__3LXOO {
  background: #ffffff;
  box-shadow: none;
  padding: 0 5rem; }
  @media screen and (max-width: 64rem) {
    .topBar__3LXOO {
      padding: 0 1.5rem; } }

.content__2kniF {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 1.375rem 0;
  align-content: center; }

.middleContainer__2r8_g {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center; }

.title__2hpwN {
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 0.005rem;
  color: #3c4666; }
  @media screen and (max-width: 64rem) {
    .title__2hpwN {
      font-size: 0.875rem; } }

.backButton__1Dxzz {
  left: 0;
  position: absolute;
  margin-right: auto;
  height: auto;
  padding: 0 1.25rem 0 0;
  display: flex; }
  .backButton__1Dxzz svg {
    width: 0.625rem; }

.logo__TmMca {
  display: block;
  text-align: center;
  margin-bottom: 0.5rem; }
  .logo__TmMca svg {
    width: 2.1875rem;
    height: 2.1875rem; }
  @media screen and (max-width: 64rem) {
    .logo__TmMca svg {
      width: 1.625rem;
      height: 1.625rem; } }
