.card__3ii1q {
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(79, 88, 122, 0.14);
  border-radius: 2px;
  margin: 0 0.875rem;
  display: flex;
  padding: 1rem 0 1rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #143268;
  align-items: center; }

.ok__3jSq3 {
  margin-right: 1.625rem;
  font-size: 1.375rem;
  background-color: #f3f6fd;
  border: 1px solid #dfe8fa;
  border-radius: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: -0.25rem; }
