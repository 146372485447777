.button__37MXk {
  display: inline-block;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 600;
  align-items: center;
  background: rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 1.5625rem;
  padding: 0.875rem 1.5625rem; }
  .button__37MXk svg {
    margin-right: 0.75rem; }

.hover__9jZ4L:hover {
  color: #000;
  background: #fff;
  mix-blend-mode: screen; }
  .hover__9jZ4L:hover svg path {
    fill: #000; }

.blueButton__2J_Jc {
  color: #ffffff;
  background: #558efc; }

.removeArrow__2hsh9 svg {
  display: none; }
