.item__3-yuQ {
  display: flex;
  flex-direction: row;
  background: #f3f6fd;
  border: 1px solid #e3eaf6;
  border-radius: 0.26rem;
  margin-bottom: 0.625rem;
  padding: 1.125rem 1.25rem;
  text-decoration: none;
  line-height: 1.5625rem;
  color: #4d5a74;
  font-weight: 500;
  font-size: 0.875rem;
  transition-property: background;
  transition-duration: 250ms; }
  .item__3-yuQ:last-child {
    margin-bottom: 0; }
  .item__3-yuQ:hover {
    background: #fff; }

.address__2e-cf {
  color: inherit;
  font-weight: inherit; }

.matrikkel__2DwzL {
  margin-left: auto;
  opacity: 0.5;
  color: #3c4666; }
