.header__1Rca2 {
  display: flex;
  align-items: center;
  min-height: 4.5rem;
  border-bottom: 1px solid rgba(20, 50, 104, 0.1);
  padding-left: 0.875rem;
  padding-right: 1.125rem; }

.icon__3O4s1 {
  margin-right: 1.25rem; }

.title__nG33j {
  flex-grow: 1;
  color: #143268;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0; }

.subtitle__2fndg {
  margin-right: 2.1875rem;
  opacity: 0.3;
  font-size: 0.75rem;
  font-weight: 600;
  justify-self: flex-end; }

.collapseArrow__mB2-b {
  transition: transform 0.3s; }

.collapseArrow__mB2-b.open__1GRc- {
  transform: rotateZ(180deg); }

.valueList__2IDYh {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600; }
  .valueList__2IDYh li + li {
    border-top: 1px solid rgba(20, 50, 104, 0.1); }
