.itemTgIUNormal__3sY7w {
  background-color: #dee2ec; }

.itemTg0Normal__1BVvD {
  background-color: #5dd371; }

.itemTg1Normal__25116 {
  background-color: #5dd371; }

.itemTg2Normal__O6YNu {
  background-color: #ff9a63; }

.itemTg3Normal__StOYF {
  background-color: #fc5955; }

.itemTgIUDarker__1eDOv {
  background-color: #c8cbd4; }

.itemTg0Darker__1T_Q1 {
  background-color: #54be66; }

.itemTg1Darker__1hdoU {
  background-color: #54be66; }

.itemTg2Darker__FBQlz {
  background-color: #e68b59; }

.itemTg3Darker__39Hw8 {
  background-color: #e3504d; }

.itemTgIULighter__19VTV {
  background-color: #e1e5ee; }

.itemTg0Lighter__1VZ8D {
  background-color: #6dd77f; }

.itemTg1Lighter__2pVC- {
  background-color: #6dd77f; }

.itemTg2Lighter__jU5sD {
  background-color: #ffa473; }

.itemTg3Lighter__2X2Ye {
  background-color: #fc6a66; }
