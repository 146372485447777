.tgMismatchContainer__kGTQ1 {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 1rem;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center; }

.tg2Tag__2RAIe {
  background-color: #ff9a63; }

.tg3Tag__bafll {
  background-color: #fc5955; }

.arrowIndicator__3lWCR {
  height: 0.6rem; }
