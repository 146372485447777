.list__26Uxu {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  max-width: 44.6875rem;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(79, 88, 122, 0.14);
  border-radius: 2px;
  color: #143268; }
