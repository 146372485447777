.arrowButton__1VMXR {
  border: none;
  outline: none;
  background: #fff;
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; }
