.modal__xKmEq {
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgba(79, 88, 122, 0.13);
  left: 50%;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: fadeInAndUp__1PQi9 500ms;
  height: auto;
  max-width: 31.25rem;
  width: calc(100% - 20px); }

.overlay__2f9-h {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10002 !important; }

.modal__xKmEq h2 {
  background: rgba(96, 132, 232, 0.1) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48bWFzayBpZD0iYSIgZmlsbD0iI2ZmZiI+PHBhdGggZD0iTTkuMzUgMTEuNDc1aC40MjVjLjQ3IDAgLjg1LjM3Ny44NS44NSAwIC40Ny0uMzc3Ljg1LS44NS44NWgtMi41NWEuODQ4Ljg0OCAwIDAxLS44NS0uODVjMC0uNDcuMzc3LS44NS44NS0uODVoLjQyNXYtMi41NWgtLjQyMmEuODUuODUgMCAxMTAtMS43SDguNWEuODUuODUgMCAwMS44NS44NXYzLjR6TTguNSAxN2E4LjUgOC41IDAgMTEwLTE3IDguNSA4LjUgMCAwMTAgMTd6bTAtMS43YTYuOCA2LjggMCAxMDAtMTMuNiA2LjggNi44IDAgMDAwIDEzLjZ6bTAtOC45MjVhMS4yNzUgMS4yNzUgMCAxMTAtMi41NSAxLjI3NSAxLjI3NSAwIDAxMCAyLjU1eiIvPjwvbWFzaz48cGF0aCBmaWxsPSIjNjA4NEU4IiBtYXNrPSJ1cmwoI2EpIiBkPSJNLTIuMTI1IDE5LjEyNWgyMS4yNXYtMjEuMjVoLTIxLjI1eiIvPjwvZz48L3N2Zz4=) no-repeat center 2.9375rem;
  color: #558efc;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  padding: 6rem 4.5625rem 2.6875rem;
  text-align: center; }

.modal__xKmEq dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2.6875rem 1.375rem; }

.modal__xKmEq dt {
  border-radius: 0.125rem;
  color: #ffffff;
  clear: left;
  float: left;
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  text-align: center;
  width: 3.125rem; }

.modal__xKmEq dt.tgiu__3TsHB {
  background-color: #afafaf; }

.modal__xKmEq dt.tg1__mMyRl {
  background-color: #3fca6c; }

.modal__xKmEq dt.tg2__3X-sL {
  background-color: #ff844c; }

.modal__xKmEq dt.tg3__1QFwa {
  background-color: #ff514e; }

.modal__xKmEq dd {
  color: #143268;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 4.75rem;
  padding-bottom: 1.875rem; }

.modal__xKmEq dd:last-child {
  padding-bottom: 0; }

.closeButton__1j-fa {
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.9375rem; }

.closeIcon__1FfKE {
  height: 0.6875rem;
  width: 0.6875rem; }
  .closeIcon__1FfKE path {
    stroke: #afb0c7; }

@keyframes fadeInAndUp__1PQi9 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
