.spacer__2zCpE {
  height: 9.375rem;
  margin-bottom: 1.8125rem; }
  @media screen and (max-width: 25.875rem) {
    .spacer__2zCpE {
      height: 3.75rem; } }

.costBar__1rUgT {
  z-index: 9999;
  background: #6084e8;
  width: 100%;
  color: #fff;
  height: 9.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.75rem;
  position: relative; }
  @media screen and (max-width: 25.875rem) {
    .costBar__1rUgT {
      height: 3.75rem;
      align-items: center;
      flex-direction: row;
      padding: 0.625rem;
      justify-content: flex-start; } }
  .costBar__1rUgT:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 30px 0 30px;
    border-color: #6084e8 transparent transparent transparent;
    position: absolute;
    bottom: 0;
    transform: translateY(100%); }
    @media screen and (max-width: 25.875rem) {
      .costBar__1rUgT:after {
        display: none; } }

.text__1QiG1 {
  font-size: 1rem;
  margin-bottom: 0.875rem; }
  @media screen and (max-width: 25.875rem) {
    .text__1QiG1 {
      font-size: 0.8125rem;
      margin: 0; } }

.number__24IV6 {
  font-weight: 600;
  font-size: 1.625rem; }
  @media screen and (max-width: 25.875rem) {
    .number__24IV6 {
      margin-left: auto;
      font-size: 1.125rem; } }

.test__2Bxf1 {
  height: 400vh; }
