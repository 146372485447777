.bar__321Hw {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 3.125rem;
  border-radius: 0.25rem;
  overflow: hidden; }

.tg__qsY-u {
  padding: 0 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  margin-right: 0.25rem; }
  .tg__qsY-u:last-child {
    margin-right: 0; }

.tg1__1h19y {
  background: #5dd371; }

.tg2__2tX8I {
  background: #ff9a63; }

.tg3__Kt6t2 {
  background: #fc5955; }

.tg1_obos__2C29r {
  font-family: Gordita-Medium, sans-serif;
  background: #62d175; }

.tg2_obos__2qLcC {
  font-family: Gordita-Medium, sans-serif;
  background: #fd9a69; }

.tg3_obos__3QFqX {
  font-family: Gordita-Medium, sans-serif;
  background: #f95a59; }
