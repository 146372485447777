.outer__3fCRj {
  width: 100%; }

.container__1nViF {
  min-width: 27.625rem;
  min-height: 26.375rem;
  padding: 1.875rem 0;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 1px 4px rgba(79, 88, 122, 0.144192); }
  @media screen and (max-width: 64rem) {
    .container__1nViF {
      box-shadow: none;
      min-width: unset;
      min-height: unset; } }

.content__3Vkz3 {
  position: relative;
  text-align: center;
  max-width: 60%; }
  .content__3Vkz3:after {
    position: absolute;
    content: "\A0";
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #fff;
    border-radius: 5px;
    left: calc(-50% + 20px);
    bottom: -63px; }
  .content__3Vkz3:before {
    position: absolute;
    content: "\A0";
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid rgba(79, 88, 122, 0.05);
    border-radius: 5px;
    left: calc(-50% + 15px);
    bottom: -64px;
    filter: blur(3px); }

.illustration__2-t2P {
  width: 10.75rem;
  height: auto; }

.headline__3Scui {
  font-family: MaisonNeue;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #414b6a; }

.lead__TWsmq {
  font-family: MaisonNeue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #414b6a; }
