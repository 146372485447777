.footer__3esZM {
  padding: 3.75rem 0;
  background: #3c4761;
  color: #ffffff;
  letter-spacing: 0.07px;
  line-height: 25px;
  display: flex;
  justify-content: space-around; }
  .footer__3esZM h2 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.5rem; }
  .footer__3esZM a,
  .footer__3esZM p {
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: inherit; }
  .footer__3esZM a:hover {
    text-decoration: underline; }

.footnote__1D8Vg {
  padding-top: 1.875rem !important; }
  .footnote__1D8Vg p {
    font-size: 0.75rem; }

.section__3N4VK {
  margin: 0 1.5rem;
  max-width: 62.5rem; }

.links__3Ng2c {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.75rem; }
  @media screen and (max-width: 25.875rem) {
    .links__3Ng2c {
      flex-direction: column; } }
  .links__3Ng2c ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (max-width: 25.875rem) {
      .links__3Ng2c ul {
        margin-bottom: 1.875rem; } }

.internalLinks__wiEgU {
  margin-top: 0;
  margin-bottom: 2.625rem;
  padding-left: 0;
  list-style: none; }
  .internalLinks__wiEgU li + li {
    margin-top: 0.5rem; }

@media screen and (max-width: 25.875rem) {
  .logoWrapper__3Uk2U {
    position: absolute;
    right: 1rem; } }

.logo__2PkxW {
  width: 2.875rem;
  height: auto; }

.socialLinks__2Xmob {
  display: flex;
  flex-direction: column; }
  .socialLinks__2Xmob ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0; }
  .socialLinks__2Xmob li:nth-of-type(2) {
    margin-left: 1.125rem;
    margin-right: 1.125rem; }
  .socialLinks__2Xmob p {
    margin: 0;
    margin-bottom: 0.625rem; }
  .socialLinks__2Xmob a {
    display: block; }
  .socialLinks__2Xmob svg {
    width: 1.875rem;
    height: auto; }
    .socialLinks__2Xmob svg:hover {
      opacity: 0.85; }
