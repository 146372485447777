.link__1YZEY {
  display: flex;
  align-items: center;
  color: #5174f2;
  font-family: MaisonNeue;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none; }
  .link__1YZEY:hover {
    text-decoration: underline; }
