.container__HeihS {
  display: inline-block;
  position: relative; }

.tag__3yqKT {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.7; }
  .tag__3yqKT.selected__wwRvo {
    opacity: 1; }

.description__mMTsw {
  font-style: normal;
  position: absolute;
  white-space: normal;
  background: white;
  color: #143268;
  font-size: 0.875rem;
  font-weight: 500;
  display: block;
  align-items: center;
  width: calc(100vw - 3rem);
  margin-left: 1.5rem;
  padding: 1rem 1rem 2rem 1rem;
  top: 2.5em;
  z-index: 5000;
  border-radius: 0.5rem;
  box-shadow: 0 5px 80px 10px rgba(0, 0, 0, 0.2);
  animation: fadein__29gZO 0.3s; }
  .description__mMTsw * {
    margin: 0; }

.descriptionHeader__2K-Ih {
  display: block;
  text-align: center;
  font-weight: 600;
  width: 100%;
  font-size: 1.125rem;
  line-height: 4rem;
  text-transform: capitalize;
  max-width: 31.25rem;
  margin: auto; }

.descriptionText__Kcteg {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 31.25rem;
  margin: auto; }

@keyframes fadein__29gZO {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
