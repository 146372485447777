.searchContainer__kKdkL {
  display: flex;
  width: 100%;
  box-shadow: 0 0.1875rem 0.8125rem 0 rgba(44, 62, 109, 0.13); }

.input__1Hi5n {
  flex: 1;
  background: #ffffff;
  font-size: 1rem;
  padding: 1.375rem 1.625rem 1.375rem 1.625rem;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none; }
  .input__1Hi5n:focus {
    outline: none; }

.searchIcon__2X8f8 {
  width: 4.6875rem;
  background: #558efc;
  border-radius: 0 0.325rem 0.325rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .searchIcon__2X8f8 svg {
    flex: 0 0 auto;
    max-width: 1.125rem;
    width: 100%; }
