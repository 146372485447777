.container__JBcUh {
  position: relative;
  padding: 0.9375rem 1.25rem; }

.header__7aAhw {
  margin-top: 0;
  margin-bottom: 0.125rem;
  opacity: 0.8;
  color: #143268;
  font-size: 12px;
  font-weight: 500; }
