.property__28ldf {
  box-shadow: 0px 2px 11px rgba(79, 88, 122, 0.06);
  background: #ffffff;
  border-radius: 4px; }
  @media screen and (max-width: 64rem) {
    .property__28ldf {
      box-shadow: none; } }
  .property__28ldf a {
    text-decoration: none; }

.address__2UjRq {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2.9375rem; }
  @media screen and (max-width: 64rem) {
    .address__2UjRq {
      padding-top: 1.5rem; } }
  .address__2UjRq h1 {
    margin: 0;
    font-family: MaisonNeue;
    color: #3c4666;
    font-weight: 600;
    line-height: 1.5625rem;
    font-size: 1.125rem; }
    @media screen and (max-width: 64rem) {
      .address__2UjRq h1 {
        font-size: 0.875rem; } }
  .address__2UjRq svg {
    width: 1rem;
    height: auto;
    margin-right: 0.75rem; }

.propertyAttributes__U1myO {
  display: grid;
  background: #f3f6fd;
  margin: 0;
  padding: 2.8125rem 0; }
  .propertyAttributes__U1myO div {
    grid-row: 1;
    text-align: center; }
  .propertyAttributes__U1myO dt,
  .propertyAttributes__U1myO dd {
    font-family: MaisonNeue;
    font-weight: 600;
    letter-spacing: 0.00375rem; }
  .propertyAttributes__U1myO dd {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #3c4761; }
  .propertyAttributes__U1myO dt {
    font-size: 0.75rem;
    line-height: 1.5625rem;
    color: #a8aec4; }
  .propertyAttributes__U1myO div:nth-of-type(2) {
    border-left: 2px solid lightgray;
    border-right: 2px solid lightgray; }

.favouriteButtonContainer__1YuJh {
  position: relative;
  width: 100%; }

.favouriteButton__2ocyS {
  position: absolute;
  top: 0;
  right: 6.25rem; }
  @media screen and (max-width: 64rem) {
    .favouriteButton__2ocyS {
      right: 1.5625rem; } }
