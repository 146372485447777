.container__33wo3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f6fd;
  padding: 0 4.5rem 3.125rem 4.5rem; }
  @media screen and (max-width: 25.875rem) {
    .container__33wo3 {
      padding: 3.125rem 1.25rem; } }

.favourites__1MSt0 {
  margin-bottom: 6.25rem; }
  .favourites__1MSt0 .heading__1Q7Y1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.375rem;
    margin-bottom: 2.25rem; }
  .favourites__1MSt0 .headline__2UJmd {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5625rem;
    color: #3c4761; }
  .favourites__1MSt0 .browse__1QXtV {
    min-height: 2.5rem;
    min-width: 5.25rem;
    background: #558efc;
    color: #ffff;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 1;
    transition: opacity 0.2s ease-in-out; }
    .favourites__1MSt0 .browse__1QXtV:hover {
      opacity: 0.9; }

.visited__1AYiF {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .visited__1AYiF h2 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5625rem;
    text-transform: uppercase;
    color: #3c4761;
    margin-bottom: 0.875rem; }

.map__-CB7I {
  width: 100%;
  height: 31.25rem;
  opacity: 0.5;
  margin-bottom: 3.125rem; }
