.header__13SlF {
  color: #143268;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.5rem; }

.subtitle__3W74p {
  color: #a8aec4;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0; }
