.container__1XO14 {
  display: flex; }
  @media screen and (max-width: 64rem) {
    .container__1XO14 {
      flex-direction: column; } }

.leftContent__1OP8H,
.rightContent__16qxh {
  width: 50%; }
  @media screen and (max-width: 64rem) {
    .leftContent__1OP8H,
    .rightContent__16qxh {
      width: 100%; } }
