.searchResults__1S9Oj {
  position: absolute;
  left: -1.25rem;
  right: -1.25rem;
  margin-top: 1.25rem;
  background: #ffffff;
  border-radius: 0.25rem;
  padding: 1.25rem; }

.notifications__2T9xW {
  position: absolute;
  margin-top: 2.5rem; }
