.chatMessage__1IxEK {
  background: #f3f6fd;
  border-radius: 4px;
  padding: 1.25rem;
  margin-bottom: 1.375rem;
  width: 90%;
  left: 10%;
  float: right; }
  .chatMessage__1IxEK span {
    display: block; }
  .chatMessage__1IxEK h2,
  .chatMessage__1IxEK p {
    margin: 0; }

.chatMessage__1IxEK:first-of-type,
.chatMessageOwner__3Q-lt {
  position: relative;
  left: 0;
  float: left; }

.message__3yl3w {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.from__1PjXn,
.sentAt__S_3j0 {
  font-family: MaisonNeue;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3125rem;
  color: inherit;
  opacity: 0.35; }

.message__3yl3w {
  font-family: MaisonNeue;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem; }
