.container__12Hxk {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%; }
  .container__12Hxk.primaryBackgroundColor__3KgRZ {
    background: #f3f6fd; }

.main__1a2Qh {
  flex-grow: 1; }

.topbar__33mxN {
  background: white;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.25rem;
  padding: 0 5rem; }
  .topbar__33mxN.boxShadow__1oPYN {
    box-shadow: 0px 2px 11px rgba(79, 88, 122, 0.06); }
  @media screen and (max-width: 25.875rem) {
    .topbar__33mxN {
      align-items: center;
      justify-content: space-between;
      padding: 0 24px; } }

.vendu_link__2-eaD {
  z-index: 1;
  display: flex;
  align-items: center; }
  .vendu_link__2-eaD .searchLink__22Zk8 {
    margin-left: 1rem; }
  .vendu_link__2-eaD svg {
    width: 2.1875rem;
    height: auto; }

.centeredContent__Zk1y4 {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media screen and (max-width: 64rem) {
    .centeredContent__Zk1y4 {
      display: none; } }

.sba__3ed3Z {
  display: flex;
  align-items: center; }
  .sba__3ed3Z svg {
    width: 2.5625rem;
    height: auto; }
  .sba__3ed3Z p {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    margin-top: 18px;
    margin-left: 16px; }
    .sba__3ed3Z p span {
      display: inline-block; }
      @media screen and (max-width: 25.875rem) {
        .sba__3ed3Z p span {
          display: block; } }

.loginLogoutWidget___0ZKp {
  z-index: 1; }

.rightContent__3CONF {
  z-index: 1;
  display: flex;
  align-items: center; }
  .rightContent__3CONF a {
    margin-right: 1rem; }

.navigationLink__3LZoL {
  font-family: MaisonNeue;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  color: #3c4761; }
  .navigationLink__3LZoL:hover {
    text-decoration: underline; }
