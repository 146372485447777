.container__Ms33o {
  display: flex;
  align-items: center; }

.link__1DBs7,
.myPageLink__2u-MX {
  font-family: MaisonNeue;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none; }

.link__1DBs7 {
  display: flex;
  align-items: center;
  color: #5174f2; }

.placeholder__3sjvw {
  width: 2.5rem;
  height: auto; }

.myPageLink__2u-MX {
  color: #3c4761; }

.logout__2KB_l {
  color: #fc5955; }

.resetButtonStyle__1Zxle {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer; }

.dropdown__3FdHi {
  position: relative; }
  @media screen and (min-width: 64.0625rem) {
    .dropdown__3FdHi:hover .dropdownContent__zNJzG {
      display: block; } }

.dropdownContent__zNJzG {
  max-width: 12.5rem;
  z-index: 10000;
  border: 1px solid lightgray;
  border-radius: 3px;
  background: white;
  position: absolute;
  top: 2.8125rem;
  right: 0;
  display: none; }
  .dropdownContent__zNJzG.show__1ohYF {
    display: block; }
  .dropdownContent__zNJzG ul {
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 8rem; }
  .dropdownContent__zNJzG li {
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .dropdownContent__zNJzG li:last-of-type {
    border-bottom: 0; }

.name__2JAk7 {
  display: flex;
  flex-direction: column;
  text-align: left; }
  .name__2JAk7 span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; }
  .name__2JAk7 span:nth-of-type(1) {
    color: #3c4761; }
  .name__2JAk7 span:nth-of-type(2) {
    font-size: 12px; }
