.wrapper__2u5M4 {
  overflow: hidden; }

.slider__3yzhs {
  width: 100%;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  max-height: 50vh;
  height: 100%; }
  @media screen and (max-width: 25.875rem) {
    .slider__3yzhs {
      height: 15.625rem; } }

.imageContainer__3LrAT {
  background-color: #f3f6fd;
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 50vh;
  height: 100%;
  text-align: center; }
  @media screen and (max-width: 25.875rem) {
    .imageContainer__3LrAT {
      height: 15.625rem; } }
  .imageContainer__3LrAT img {
    margin: auto;
    max-height: 50vh;
    height: 100%;
    width: auto; }

.rightButton__3TfZb {
  position: absolute;
  right: 8.19444%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition-property: opacity;
  transition-duration: 250ms;
  opacity: 0;
  pointer-events: none; }

.leftButton__5CKdf {
  position: absolute;
  left: 8.19444%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition-property: opacity;
  transition-duration: 250ms;
  opacity: 0;
  pointer-events: none; }

.sliderContainer__1Mnnr {
  position: relative; }

.buttonsContainer__VCPXA {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }
  @media screen and (max-width: 64rem) {
    .buttonsContainer__VCPXA {
      display: none; } }
  .buttonsContainer__VCPXA .col__2kE0H {
    flex: 1 1 auto;
    cursor: pointer; }
  .buttonsContainer__VCPXA .hoverButton__1Swe3 {
    opacity: 1; }

.slick-track {
  display: flex !important; }

.slick-slide {
  height: inherit !important; }
  .slick-slide > div {
    height: 100%; }

.slick-arrow {
  display: none !important; }
