.creatable-select__control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #f3f6fd !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 60px !important; }

.creatable-select__multi-value {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: #3c4761 !important; }
  .creatable-select__multi-value__remove {
    color: #ffffff !important;
    opacity: 0.5 !important; }
  .creatable-select__multi-value__label {
    color: white !important;
    font-family: MaisonNeue !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 25px !important; }

.creatable-select__value_container {
  padding: 16px !important; }

.creatable-select__placeholder {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5625rem;
  color: #3c4761; }

/*
[prefix]__clear-indicator
[prefix]__control
[prefix]__dropdown-indicator
[prefix]__group
[prefix]__group-heading
[prefix]__indicator
[prefix]__indicators-container
[prefix]__indicator-separator
[prefix]__input
[prefix]__loading-indicator
[prefix]__menu
[prefix]__menu-list
[prefix]__menu-portal
[prefix]__menu-notice
[prefix]__menu-notice--loading (LoadingMessage)
[prefix]__menu-notice--no-options (NoOptionsMessage)
[prefix]__multi-value
[prefix]__multi-value__label
[prefix]__multi-value__remove
[prefix]__option
[prefix]__placeholder
[prefix]__single-value
[prefix]__value-container
[prefix]__value-container--is-multi
*/
