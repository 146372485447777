.participants__1TzRS {
  margin-bottom: 1.25rem;
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #3c4761;
  opacity: 0.4; }
  .participants__1TzRS .label__3snYI {
    margin-right: 0.5rem; }
  .participants__1TzRS ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0; }
    .participants__1TzRS ul li {
      margin-right: 0.25rem; }
