.button__3h9bH {
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  will-change: opacity;
  transition: opacity ease-out 250ms; }
  .button__3h9bH svg {
    height: auto;
    width: 3.125rem; }
    @media screen and (max-width: 64rem) {
      .button__3h9bH svg {
        width: 2.5rem; } }
  .button__3h9bH:hover {
    opacity: 0.85; }

.filled__fGEEX {
  fill: #558efc; }
