.error__SKMMJ {
  color: #143268;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  text-align: center;
  justify-content: center; }
  .error__SKMMJ p {
    font-size: 1rem; }

.error_nylander__21kbG {
  color: white; }

.title__toG4i {
  font-size: 1.625rem; }

.back__14kaq {
  margin-top: 3.125rem; }
