.linkButtonContainer__2a4hR {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(79, 88, 122, 0.14);
  border-radius: 0.25rem;
  padding: 1.25rem 1.875rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.125rem;
  color: #143268;
  letter-spacing: 0.07px;
  font-weight: 600;
  will-change: opacity;
  transition: opacity ease-out 250ms; }
  @media screen and (max-width: 64rem) {
    .linkButtonContainer__2a4hR {
      font-size: 0.875rem;
      color: #3c4666;
      padding: 1.25rem 1.25rem;
      margin: 0 1.25rem; } }
  .linkButtonContainer__2a4hR:hover {
    opacity: 0.75; }

.icon__29Lgx {
  width: 1.9375rem;
  height: 1.9375rem;
  border: 2px solid rgba(81, 116, 242, 0.2);
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 25.875rem) {
    .icon__29Lgx {
      width: 1.625rem;
      height: 1.625rem; } }
  .icon__29Lgx svg {
    margin-left: 0.125rem; }
    @media screen and (max-width: 25.875rem) {
      .icon__29Lgx svg {
        height: 0.625rem;
        margin-left: 0; } }

.marginTop__1NgCz {
  margin-top: 1.25rem; }
