.container__1uzxc {
  flex-grow: 0;
  position: relative;
  background: #ffffff;
  color: #143268;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 21.875rem;
  margin-top: 1rem; }

.headerBox__1ofzR {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0 0.5rem; }
  .headerBox__1ofzR .header__1Fn5K {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: #143268; }
  .headerBox__1ofzR .arrow__W6UVR {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .headerBox__1ofzR .arrow__W6UVR svg {
      height: 1rem;
      width: 1rem; }
    .headerBox__1ofzR .arrow__W6UVR.tg2Tag__YbRmG {
      background-color: #ff9a63; }
    .headerBox__1ofzR .arrow__W6UVR.tg3Tag__3Rnrg {
      background-color: #fc5955; }

.description__2izSI {
  font-size: 0.875rem;
  font-weight: 400;
  color: #143268;
  width: 100%;
  position: relative;
  transition: 0.2s ease height;
  padding: 0.5rem 2.5rem; }
  .description__2izSI p {
    margin: 0; }
  .description__2izSI.truncate__2BKoR {
    display: none; }

.bottomBox__ul4Ob {
  display: flex;
  position: relative;
  padding: 0 0.5rem;
  align-items: flex-start;
  justify-content: flex-start; }
  .bottomBox__ul4Ob button {
    color: #a8aec4;
    flex-grow: 0;
    margin: 0;
    outline: none;
    border-radius: 0;
    background: transparent;
    border: none;
    text-align: left;
    padding-left: 2rem;
    -webkit-appearance: none; }
  .bottomBox__ul4Ob .venduHeart__1WX9d {
    position: absolute;
    right: 0.5rem;
    bottom: 0.3rem; }
    .bottomBox__ul4Ob .venduHeart__1WX9d svg {
      height: 1rem;
      width: 1rem; }

@keyframes fadein__IZNYq {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
