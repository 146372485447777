.container__1gbAV {
  background: #f3f6fd;
  padding-bottom: 7.25rem; }
  @media screen and (max-width: 64rem) {
    .container__1gbAV {
      padding-bottom: 3.75rem; } }

.wrapper__2HMYa {
  max-width: 62.5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.statusBarLink__3ZRFu {
  text-decoration: none;
  display: block;
  margin-top: -3.125rem;
  position: relative;
  z-index: 9198; }
  @media screen and (max-width: 64rem) {
    .statusBarLink__3ZRFu {
      margin-top: 0; } }

.linkButtons__37Sth {
  margin-top: 2.5rem; }
  @media screen and (max-width: 64rem) {
    .linkButtons__37Sth {
      margin-top: 0; } }

.breadcrumbsContainer__3IBZ9 {
  padding: 1rem 5rem; }
  @media screen and (max-width: 64rem) {
    .breadcrumbsContainer__3IBZ9 {
      padding: 1rem 1.5rem; } }

.reportOverview__eVCtC {
  background-color: #f3f6fd; }
