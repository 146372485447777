.container__1PZTe {
  background: #fff;
  width: 100%; }

.form__pHEWb {
  display: flex;
  flex-direction: column; }
  .form__pHEWb input,
  .form__pHEWb textarea {
    padding: 1rem;
    background: #f3f6fd;
    width: 100%;
    border: none;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #3c4761;
    opacity: 0.7; }
  .form__pHEWb textarea {
    padding: 16px;
    resize: none;
    width: 100%;
    display: block;
    box-sizing: border-box; }
  .form__pHEWb label {
    font-family: MaisonNeue;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    color: #3c4761;
    margin-bottom: 0.35rem; }

.content__1lFwN {
  margin: 1.5rem 1.5rem 2rem 1.5rem; }

.inputGroup__1jr4O {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem; }
  .inputGroup__1jr4O:last-of-type {
    margin-bottom: 1.875rem; }

.buttonContainer__1Hyqy {
  text-align: center;
  margin-top: 0.75rem; }
  .buttonContainer__1Hyqy button {
    border-radius: 4px; }

.hide__2Sr__ {
  display: none; }

.errorMessage__SH0tg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fdfbe3;
  border: 1.04px solid #e7e2ba;
  box-sizing: border-box;
  border-radius: 4.16px;
  padding: 0.125rem 0.625rem;
  font-family: MaisonNeue;
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  margin-bottom: 10px;
  color: #cfc78a; }
