.article__34vFc {
  background: #fff;
  padding: 1.25rem 2.5rem;
  margin: 1.25rem auto 0 auto;
  max-width: 64rem;
  color: #3c4666;
  overflow-wrap: break-word; }
  @media screen and (max-width: 64rem) {
    .article__34vFc {
      padding: 1.25rem; } }
  .article__34vFc h1 {
    font-size: 1.375rem; }
  .article__34vFc h2 {
    font-size: 1.25rem; }
  .article__34vFc h3 {
    font-size: 1.125rem; }
  .article__34vFc h4 {
    font-size: 1rem; }
  .article__34vFc h5 {
    font-size: 1rem; }

.link__1QJmf {
  display: inline-block;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  border: none;
  padding: 0.7rem 2rem;
  margin-top: 1.875rem;
  background-color: #558efc;
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
  outline: none;
  cursor: pointer; }

.quote__RcFzm {
  margin: 1.875rem 0 0 0;
  padding: 1.25rem;
  border-left: 10px solid #ddd; }
  .quote__RcFzm > p:first-child {
    margin-top: 0; }
