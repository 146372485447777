.header__16Pns {
  padding: 0 1.25rem;
  color: #3c4761;
  font-weight: 600;
  font-size: 2.5rem;
  letter-spacing: 0.18px;
  text-align: center;
  margin: 0 0 1.4375rem 0; }
  @media screen and (max-width: 25.875rem) {
    .header__16Pns {
      font-size: 1.25rem;
      text-align: left; } }

.subHeader__300jA {
  padding: 0 1.25rem;
  color: #3c4761;
  font-weight: 400;
  font-size: 1.625rem;
  text-align: center;
  margin: 0; }
  @media screen and (max-width: 25.875rem) {
    .subHeader__300jA {
      text-align: left;
      font-size: 1.125rem; } }
