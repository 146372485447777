.headerBox__3u76p {
  padding-top: 5.125rem;
  padding-bottom: 2.6875rem; }

.improvements__1Di7l {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }
  .improvements__1Di7l li {
    margin-top: 0;
    margin-bottom: 0.625rem; }
