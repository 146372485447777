.page__MqT2U {
  padding: 0 5rem;
  display: flex;
  justify-content: space-around;
  padding-top: 1.875rem;
  padding-bottom: 7.5rem; }
  @media screen and (max-width: 25.875rem) {
    .page__MqT2U {
      padding: 0 1.5rem; } }
  @media screen and (max-width: 25.875rem) {
    .page__MqT2U {
      padding-top: 0.625rem;
      padding-bottom: 3.75rem; } }
  .page__MqT2U ul li,
  .page__MqT2U ol li {
    margin-bottom: 0.25rem; }
  .page__MqT2U h1,
  .page__MqT2U h2,
  .page__MqT2U h3,
  .page__MqT2U h4,
  .page__MqT2U p {
    color: #3c4761;
    line-height: 1.25em; }
  .page__MqT2U h3 {
    margin-top: 2.5rem; }
  .page__MqT2U h4 {
    margin-top: 1.875rem;
    margin-bottom: 1.25rem; }
  .page__MqT2U p {
    font-size: 1rem;
    font-weight: 400; }
  .page__MqT2U .wrapper__1DacZ {
    max-width: 50rem; }
  .page__MqT2U .section__1hzyi {
    margin-bottom: 3.75rem; }
    .page__MqT2U .section__1hzyi:last-of-type {
      margin-bottom: 0; }
