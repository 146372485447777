.header__1hiO6 {
  padding-top: 5.125rem;
  padding-bottom: 2.6875rem; }

.compareContainer__dNUqq {
  max-width: 48.4375rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.buildingPartHeader__2DhTM {
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  border-bottom: 1px solid #dee2ec;
  font-family: MaisonNeue-Demi;
  font-size: 1.125rem;
  color: #414b6a; }

.categoryName__GRy1v {
  font-family: MaisonNeue-Demi;
  font-size: 1rem;
  color: #414b6a;
  margin: 1.875rem 0 1.25rem 0; }

.flexContainer__3bWt- {
  display: flex;
  margin-bottom: 2.5rem; }

.col__32pOz {
  flex: 1 0 50%;
  overflow: hidden;
  padding: 0 2.1875rem 2.1875rem 0; }
  .col__32pOz + .col__32pOz {
    border-left: 1px solid #dee2ec;
    padding: 0 0 2.1875rem 2.1875rem; }

.compareList__3pLpj {
  margin: 0;
  padding: 0;
  width: 100%; }
