p {
  line-height: 1.4; }

.eikaActionButton__30Gj4 {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem;
  border-radius: 24px;
  color: #333;
  background-color: #84bd00; }
  .eikaActionButton__30Gj4:hover, .eikaActionButton__30Gj4:focus {
    background-color: #008e1f; }

section {
  margin-bottom: 3rem;
  margin-top: 3rem; }

section:first-child {
  margin-top: 0rem; }

.rowReverse__3d3xr {
  flex-direction: row-reverse; }

.sectionHeader__15G3o {
  text-align: center;
  margin-bottom: 4rem; }

.doubleColumnSection__2dCEj {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6rem; }
  .doubleColumnSection__2dCEj img {
    margin-bottom: 2rem; }
  .doubleColumnSection__2dCEj > div {
    width: 100%; }
  @media screen and (min-width: 768px) {
    .doubleColumnSection__2dCEj > div {
      width: 50%; } }
  .doubleColumnSection__2dCEj h1,
  .doubleColumnSection__2dCEj h2,
  .doubleColumnSection__2dCEj h3 {
    text-align: center; }

.dotSection__1LJYQ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center; }
  .dotSection__1LJYQ > div {
    width: 100%;
    margin-bottom: 3rem; }
  @media screen and (min-width: 768px) {
    .dotSection__1LJYQ > div {
      width: 33%; } }

.uspCheckmark__1KNpc {
  width: 48px;
  height: 48px;
  margin-bottom: 0.25rem; }

.articleSection__S2No7 {
  padding: 1.25rem 1.25rem; }

.doubleColumnSectionContent__2uLul {
  margin: 1rem; }

.sectionSeparator__47pKG {
  width: 3rem;
  height: 3px;
  background-color: #3c4666;
  display: block;
  margin: 2rem auto; }

.article__3Uyxf {
  background: #fff;
  margin: 0.625rem auto 0 auto;
  max-width: 64rem;
  color: #3c4666;
  overflow-wrap: break-word; }
  @media screen and (max-width: 64rem) {
    .article__3Uyxf {
      padding: 0.625rem; } }
  .article__3Uyxf h1 {
    font-size: 1.625rem; }
  .article__3Uyxf h2 {
    font-size: 1.5rem; }
  .article__3Uyxf h3 {
    font-size: 1.375rem; }
  .article__3Uyxf h4 {
    font-size: 1rem; }
  .article__3Uyxf h5 {
    font-size: 1rem; }

.link__3Ayzn {
  display: inline-block;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  border: none;
  padding: 0.7rem 2rem;
  margin-top: 1.875rem;
  background-color: #558efc;
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
  outline: none;
  cursor: pointer; }

.quote__3ZUwO {
  margin: 1.875rem 0 0 0;
  padding: 1.25rem;
  border-left: 10px solid #ddd; }
  .quote__3ZUwO > p:first-child {
    margin-top: 0; }
