.container__3qcP- {
  display: flex;
  flex-direction: row;
  background: #fff;
  color: #4d5a74;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  box-shadow: 0 2px 8px 0 rgba(79, 88, 122, 0.13); }

.adressContainer__1EDoe {
  margin-left: 0.625rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem; }

.street__3bsA5 {
  font-weight: 600;
  margin-bottom: 0.1875rem; }

.arrow__1xC4f {
  margin-left: auto;
  margin-right: 1.375rem; }

.imageContainer__xViJA {
  width: 6.25rem;
  height: 6.25rem;
  position: relative;
  overflow: hidden; }

.image__3vPe0 {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
