.section__2SMhD {
  padding: 0 1.25rem;
  background: #eef3fe;
  display: flex;
  justify-content: space-around; }

.content__2ocC8 {
  width: 40rem; }
  @media screen and (max-width: 25.875rem) {
    .content__2ocC8 {
      width: none; } }

.examples__2wSkW {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 4.375rem; }
  .examples__2wSkW li {
    margin-bottom: 1.25rem; }
    .examples__2wSkW li:last-of-type {
      margin-bottom: 0; }

.header__373IW {
  color: #4d5a74;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: 25px;
  margin-top: 3.75rem;
  margin-bottom: 0.875rem;
  text-transform: uppercase;
  text-align: left;
  width: 100%; }
