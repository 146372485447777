.breadcrumbs__-boNd {
  color: #3c4761;
  font-family: MaisonNeue;
  font-weight: bold;
  font-size: 0.75rem;
  margin-bottom: 1rem; }

.breadcrumbsLink__1UEGC {
  color: currentColor;
  text-decoration: none; }
  .breadcrumbsLink__1UEGC span:first-of-type {
    text-decoration: none; }
    .breadcrumbsLink__1UEGC span:first-of-type:hover {
      text-decoration: underline; }
  .breadcrumbsLink__1UEGC .separator__38J35 {
    padding-left: 8px;
    padding-right: 8px; }
