.section__3IY0l {
  background: #ffffff;
  max-width: 44.6875rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.875rem; }
  @media screen and (max-width: 64rem) {
    .section__3IY0l {
      width: unset; } }

.formItem__1qggv:not(:last-child) {
  border-bottom: 1px solid rgba(20, 50, 104, 0.1); }

.totalPerMonth__2DqPk {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0.5rem;
  display: block; }
