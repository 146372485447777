.modal__3ShJl {
  background: #fff;
  max-width: 33.125rem;
  min-width: 19.8125rem;
  align-items: center;
  position: fixed;
  border-radius: 0.125rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgba(79, 88, 122, 0.13);
  left: 50%;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: fadeInAndUp__sdw-n 500ms;
  width: auto;
  height: auto; }
  @media screen and (max-width: 64rem) {
    .modal__3ShJl {
      height: 90vh;
      width: 90%; } }
  .modal__3ShJl h2 {
    color: white;
    background: #558efc;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 3rem 1rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-align: center;
    width: 100%; }
  .modal__3ShJl p {
    margin: 2.25rem 1.625rem 2.5rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: 1.5rem;
    white-space: pre-wrap; }

.slider__3mwsa {
  width: 90%;
  margin-left: auto;
  margin-right: auto; }
  .slider__3mwsa * {
    outline: none; }
  .slider__3mwsa p {
    margin: 1.25rem 1.25rem 0.625rem; }

.buttonContainer__1LA22 {
  align-items: flex-end;
  display: flex;
  margin: 1rem 1rem 1.5rem 1rem;
  flex: 1; }

.button__1M95I {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  border: none;
  padding: 0.7rem 2rem;
  background-color: #eef3fe;
  color: #558efc;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.07px;
  line-height: 1.5625rem;
  text-decoration: none;
  outline: none;
  margin: 0 auto; }

.arrow__uNmZj {
  width: 0.625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem; }

.slick-disabled {
  opacity: 0.4;
  cursor: default; }

.slick-dots button:before {
  font-size: 10px !important;
  color: #558efc !important; }

.pageNumber__3PxR5 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-align: center; }
