.listItem__g_UC4 {
  min-height: 18.75rem;
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
  background: #fff;
  box-shadow: 0 1px 4px rgba(79, 88, 122, 0.1023);
  display: flex;
  align-items: center;
  justify-content: center; }
  .listItem__g_UC4:hover {
    box-shadow: 0 1px 4px rgba(79, 88, 122, 0.1023) !important; }

.container__26MMx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center; }
  .container__26MMx .textContent___1n9T {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .container__26MMx h2 {
    margin-top: 0;
    font-family: Maison Neue;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #3c4761; }
  .container__26MMx p {
    margin-top: 0;
    font-family: Maison Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #3c4761;
    margin-bottom: 2.875rem; }
  .container__26MMx .button__s2vSo {
    border: none;
    border-radius: 2px;
    min-height: 2.5rem;
    min-width: 5.25rem;
    background: #558efc;
    color: #ffff;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 1;
    transition: opacity 0.2s ease-in-out; }
    .container__26MMx .button__s2vSo:hover {
      opacity: 0.9; }
  .container__26MMx .icon__2qSBI {
    width: 1rem;
    height: auto;
    margin-bottom: 2rem; }
