.container__12shU {
  padding: 1.5rem 1rem;
  background-color: #73e190;
  font-size: 0.875rem;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  font-weight: 600;
  margin-top: 1.875rem; }

.amount__16UUn {
  margin-left: auto; }

.negative__x0EHb {
  background-color: #ff514e; }
