.cookiesPopup__24Vbq {
  position: fixed;
  bottom: 0;
  background: #558efc;
  opacity: 0.9;
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: rgba(255, 255, 255, 0.5);
  text-align: center;
  z-index: 2; }
  .cookiesPopup__24Vbq p {
    font-weight: 400;
    font-size: 0.8rem;
    margin: 0; }
  .cookiesPopup__24Vbq .readMore__3pE0E {
    background: transparent;
    font-size: 0.8rem;
    color: inherit;
    display: inline-block;
    outline: none;
    border: none;
    text-decoration: underline; }
  .cookiesPopup__24Vbq .accept__2M-yC {
    color: #fff;
    background-color: #558efc;
    outline: none;
    border: 2px solid #fff;
    width: 5rem;
    height: 2rem;
    margin-top: 1rem; }
  .cookiesPopup__24Vbq a {
    color: #fff;
    font-weight: 400;
    font-size: 0.8rem;
    margin: 0; }
