.container__1yskw {
  background: #fff;
  width: 100%; }
  @media screen and (max-width: 64rem) {
    .container__1yskw {
      height: 100vh;
      display: flex;
      flex-direction: column; } }
  .container__1yskw .creatable-select__value-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.topSection__dEwXX {
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-top: 1.5rem; }

.editParticipants__2LW9E button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  height: 1rem;
  border-bottom: 1px solid black;
  margin-bottom: 1rem; }

.errorMessage__1BE_s {
  color: #fc5955; }

.contentTop__3dwnH {
  padding-bottom: 0.75rem;
  display: none; }
  .contentTop__3dwnH.show__36RoM {
    display: block; }

.sendMessageWrapper__1gDUO {
  border-top: 1px solid lightgray;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 0.75rem; }

.chatMessages__mjV_O {
  flex: 1;
  overflow: auto;
  max-height: 32.1875rem;
  height: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse; }
  @media screen and (max-width: 64rem) {
    .chatMessages__mjV_O {
      max-height: unset; } }
  .chatMessages__mjV_O .chatMessagesInner__1XZWR {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 1.5rem;
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column; }

.emailInputWrapper__130PP {
  display: flex;
  margin-bottom: 0.5rem; }

.inputGroup__i4hmk label {
  font-family: MaisonNeue;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3125rem;
  color: inherit;
  opacity: 0.35; }

.emailInput__1tvFt {
  flex-grow: 1; }

.participantsButtonWrapper__2ANj1 {
  display: flex; }

.sendMessageContainer__2uEPk {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-top: 1.5rem; }

.sendMessageButton__18IAv,
.addParticipantButton__35di4 {
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: MaisonNeue;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  background: #558efc;
  color: #ffffff;
  min-height: 2.125rem;
  min-width: 4rem;
  transition: opacity ease-in-out 160ms; }
  .sendMessageButton__18IAv:hover,
  .addParticipantButton__35di4:hover {
    box-shadow: 0 2px 2px 0 lightgray; }
  .sendMessageButton__18IAv:disabled,
  .addParticipantButton__35di4:disabled {
    opacity: 0.75;
    box-shadow: none; }

.sendMessageButton__18IAv {
  border-radius: 4px; }

.addParticipantButton__35di4 {
  min-width: 4.375rem; }

.sendMessageButton__18IAv {
  position: absolute;
  right: 0.9375rem;
  margin-top: 1.25rem; }

.participantsWrapper__jcL_V {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.sendMessageLabel__1xRW8 {
  padding-top: 1rem; }

.addParticipantsWrapper__1JX6f {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  border-bottom: 1px solid lightgray; }
