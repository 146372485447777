.item__1wFcy {
  padding: 0.625rem 1.25rem 0.625rem 1.25rem; }
  .item__1wFcy + .item__1wFcy {
    border-top: 1px solid rgba(20, 50, 104, 0.1); }

.description__2Qvuc {
  opacity: 0.8;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  padding: 0; }

.amount__3-xhe {
  font-size: 1.25rem;
  font-weight: 600;
  display: block;
  margin-top: 0.4375rem; }
