.container__UszUd {
  border-radius: 4px;
  width: 100%;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  font-weight: 500;
  font-size: 0.8125rem;
  padding: 1.5rem 10%; }

.info__3Prpa {
  background: #558efc;
  border-color: #236dfb; }

.error__2xIuW {
  background: #fc5955;
  border-color: #fb2823; }

.danger__3b71m {
  background: #fdfbe3;
  border-color: #faf4b3; }
