.list__3Dsm_ {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.875rem; }
  @media screen and (max-width: 75rem) {
    .list__3Dsm_ {
      grid-template-columns: repeat(3, 1fr); } }
  @media screen and (max-width: 64rem) {
    .list__3Dsm_ {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 25.875rem) {
    .list__3Dsm_ {
      grid-template-columns: repeat(1, 1fr); } }
  .list__3Dsm_ a {
    text-decoration: none; }
  .list__3Dsm_ li:hover {
    box-shadow: 0px 2px 8px rgba(79, 88, 122, 0.2023); }

.button_group__yd03P {
  margin-bottom: 1rem; }
