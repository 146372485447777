.tgBadge__1h5Bt {
  margin-left: auto;
  border: none;
  background: none;
  padding: 0;
  outline: none; }
  .tgBadge__1h5Bt span {
    color: #fff;
    display: block;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 600;
    width: 3.3125rem;
    height: 3.3125rem;
    line-height: 3.3125rem; }
