.header__3NdY_ {
  font-weight: 600;
  margin-top: 2rem;
  font-size: 1.25rem; }

.text__2qV_8 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.7rem;
  white-space: pre-wrap;
  width: 100%;
  font-style: italic; }
