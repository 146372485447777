.modal__28BzY {
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgba(79, 88, 122, 0.13);
  left: 50%;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: fadeInAndUp__xwd97 500ms;
  height: auto;
  max-width: 31.25rem;
  width: calc(100% - 20px); }

.overlay__3rc44 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10002 !important; }

.modal__28BzY dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2.6875rem 1.375rem; }

.modal__28BzY dt {
  border-radius: 0.125rem;
  color: #ffffff;
  clear: left;
  float: left;
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  text-align: center;
  width: 3.125rem; }

.modal__28BzY dt.tgiu__2_PgT {
  background-color: #afafaf; }

.modal__28BzY dt.tg1__3fl03 {
  background-color: #3fca6c; }

.modal__28BzY dt.tg2__uHRgD {
  background-color: #ff844c; }

.modal__28BzY dt.tg3__3agbK {
  background-color: #ff514e; }

.modal__28BzY dd {
  color: #143268;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 4.75rem;
  padding-bottom: 1.875rem; }

.modal__28BzY dd:last-child {
  padding-bottom: 0; }

.closeButton__cl_F7 {
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.9375rem; }

.closeIcon__2gBK5 {
  height: 0.6875rem;
  width: 0.6875rem; }
  .closeIcon__2gBK5 path {
    stroke: #afb0c7; }

@keyframes fadeInAndUp__xwd97 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.topbar__1KRtO {
  min-height: 4rem;
  background: #558efc;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  position: relative; }
  .topbar__1KRtO h2 {
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #fff;
    margin-left: 1rem; }
  .topbar__1KRtO .chatIcon__2xV3F {
    width: 2.25rem;
    height: auto; }
  .topbar__1KRtO .button__3OxRZ {
    cursor: pointer;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.5rem; }
    .topbar__1KRtO .button__3OxRZ svg {
      width: 0.625rem;
      height: auto; }

.improvementsWrapper__8FhTN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 44px 20px 20px 20px; }

.improvements__OTZ6z {
  width: 100%;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }
  .improvements__OTZ6z li {
    margin-top: 0;
    margin-bottom: 0.625rem; }

.closeButton__cl_F7 {
  cursor: pointer;
  position: relative;
  min-height: 2.5rem;
  min-width: 5.25rem;
  background: #558efc;
  color: #ffff;
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  margin-top: 20px; }
  .closeButton__cl_F7:hover {
    opacity: 0.9; }
