.itemTopContainer__1pvcE {
  padding: 1.875rem 4.6875rem;
  min-height: 100vh;
  position: relative; }
  .itemTopContainer__1pvcE > * {
    color: #fff; }
  @media screen and (max-width: 64rem) {
    .itemTopContainer__1pvcE {
      padding: 1.5rem 1rem; } }

.headerBox__2MU2U {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3.125rem;
  margin-bottom: 1.875rem; }

.category__2oHUm {
  font-size: 1.5625rem;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 0.5rem;
  display: flex; }

.subcategory__quHNC {
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 0.3125rem;
  margin-bottom: 0; }

.detailsHeader__322bz {
  font-weight: 600;
  margin-top: 2rem;
  font-size: 1.25rem; }

.breadcrumbsContainer__lFYYn .breadcrumbs__ogAnN {
  color: #fff; }
