.list__3heQP {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem;
  max-width: 30.625rem; }

.listItem__1_G9y {
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(79, 88, 122, 0.133492);
  margin-bottom: 0.4375rem;
  display: flex;
  flex-shrink: 0;
  align-items: center; }

.text__2r9Xg {
  flex-grow: 1;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .text__2r9Xg b {
    font-weight: 600;
    font-size: 0.875rem; }
  .text__2r9Xg b,
  .text__2r9Xg span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block; }

.arrow__xmM8F {
  margin-right: 1.625rem;
  height: 0.75rem;
  width: auto; }

.imageWrapper__LA3Rl {
  margin-right: 1rem; }
  .imageWrapper__LA3Rl img {
    object-fit: cover;
    width: 5.4375rem;
    height: 3.75rem;
    display: block; }
  .imageWrapper__LA3Rl div {
    width: 5.4375rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0e7f7; }
  .imageWrapper__LA3Rl svg {
    width: 2.5rem;
    height: auto; }
