.container__3-HDO {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 0.75rem 0; }
  .container__3-HDO img {
    height: 2.5rem;
    margin: 0 0.625rem 0 0; }
  .container__3-HDO h3 {
    font-size: 0.875rem;
    font-weight: 600;
    border: none; }
