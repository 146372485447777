.head__2l_VE {
  background: #558efc;
  min-height: 3.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .head__2l_VE > span {
    display: flex;
    align-items: center; }
    .head__2l_VE > span svg {
      width: 2.25rem;
      height: auto;
      margin: 0 1.5rem; }
    .head__2l_VE > span p {
      color: #fff;
      font-family: MaisonNeue;
      font-size: 0.875rem;
      font-weight: 600;
      height: 100%;
      margin: 0; }
  .head__2l_VE > svg {
    margin-right: 30px; }
