.container__1Z1fG {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 330px;
  background-color: white !important;
  text-decoration: none; }

.container_nylander__qSN_b {
  background-color: #3b3c43 !important; }

.container_notar__j-t4m {
  background-color: #f0f0f0 !important; }

.container_garanti__3EUY7 {
  background-color: #ffffff !important;
  padding-bottom: 20px; }

.header__30Us7 {
  border-bottom: 1px solid #dfe8fa; }
  .header__30Us7 .topBar__2OLCP {
    display: flex;
    color: #143268;
    align-items: center;
    padding: 0.5rem; }
    .header__30Us7 .topBar__2OLCP h2 {
      font-size: 1rem;
      font-weight: 600; }
    .header__30Us7 .topBar__2OLCP h3 {
      font-size: 1rem;
      font-weight: 600; }
    .header__30Us7 .topBar__2OLCP svg {
      height: 1.25rem;
      margin-right: 0.5rem; }
  .header__30Us7 .topBar_nylander__1GDpW {
    color: white; }
  .header__30Us7 .topBar_notar__2lref {
    color: #222222; }
  .header__30Us7 .introduction__3zpC3 {
    display: block;
    margin-top: 0;
    color: #3c4666;
    font-size: 0.9375rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .header__30Us7 .introduction_nylander__3C-vO {
    color: white; }
  .header__30Us7 .introduction_notar__1UCT2 {
    color: #222222; }
  .header__30Us7 .introduction_obos__2LBgc {
    font-family: Gordita-Light, sans-serif;
    max-width: 425px; }

.counts__2hLHf {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  padding: 1rem !important;
  text-decoration: none; }
  .counts__2hLHf p {
    font-weight: 300;
    color: #3c4666;
    font-size: 0.9375rem;
    margin: 0 0 1rem; }
  .counts__2hLHf span {
    font-weight: 500; }

.counts_nylander__MCVUv p {
  color: white; }

.counts_notar__23Fjc p {
  color: #222222; }

.counts_obos__36HS3 p {
  font-family: Gordita-Light, sans-serif; }

.counts_obos__36HS3 span {
  font-family: Gordita-Medium, sans-serif; }

.bar__1PAA1 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 3.125rem; }

.tg__iJoy- {
  padding: 0 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  margin-right: 0.25rem; }
  .tg__iJoy-:last-child {
    margin-right: 0; }

.tg1__3_BuF {
  background: #5dd371; }

.tg2__3NVdJ {
  background: #ff9a63; }

.tg3__3qioQ {
  background: #fc5955; }

.footer__2pQp6 {
  background-color: #0e4ea8 !important;
  border-radius: 2px;
  margin: 0 1rem !important;
  width: 180px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 1rem; }
  .footer__2pQp6 svg {
    height: 0.5rem;
    margin-left: 0.5rem; }

.footer_obos__2fo-w {
  font-family: Gordita-Medium, sans-serif;
  font-size: 0.8125rem; }

.footer_nylander__3b_P1 {
  background-color: #5d7f99 !important; }

.footer_notar__ShlNC {
  color: #f0f7f3;
  background-color: #51a686 !important; }
