.input__1JcmG {
  display: inline-block;
  width: 100%;
  margin-top: 0.375rem;
  padding: 0.6875rem 0.9375rem 0.875rem 0.9375rem;
  border: 1px solid #b8c1d1;
  border-radius: 4px;
  background: rgba(241, 244, 253, 0.4);
  font-size: 16px;
  font-weight: 600;
  -webkit-appearance: none; }

.preview__Xjx-m {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row; }

.previewText__2QbML {
  align-self: center; }

.editIcon__2-4bC {
  margin-left: auto; }

.input__1JcmG::placeholder {
  opacity: 0.2; }

.icon__2f--F {
  position: absolute;
  right: 1.125rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; }
