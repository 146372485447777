.propertyStatusWrapperContainer__3PGeJ {
  background: #fff; }

.propertyStatusWrapper__1Fq91 {
  max-width: 47.1875rem;
  margin: 0 auto;
  box-shadow: none; }

.container__3gcAv {
  max-width: 47.1875rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 3.75rem 1.25rem; }

.breadcrumbsContainer__1colu {
  margin: 1rem 5rem; }
  @media screen and (max-width: 64rem) {
    .breadcrumbsContainer__1colu {
      margin: 1rem 1.5rem; } }
