.container__uxzyA {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  padding-bottom: 3.125rem;
  color: #143268; }

.button__tGIpk {
  background: none;
  border: none;
  color: #4d5a74;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  text-decoration: underline; }

.loader__2rkh4 {
  width: 1.875rem;
  height: 1.875rem;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 250ms; }

.loading__3vF90 {
  opacity: 1; }

.collapseWrapper__3AAqJ {
  box-shadow: 0 2px 10px 0 rgba(79, 88, 122, 0.13);
  transition: height 250ms; }

.collapseContent__1v_Gg {
  background: #f6f9ff;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 1.75rem 2.1875rem; }
  .collapseContent__1v_Gg h2 {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase; }
  .collapseContent__1v_Gg h2:not(:first-child) {
    margin-top: 1.875rem; }
