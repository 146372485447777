.noScroll__ts_UH {
  overflow: hidden;
  margin: 0;
  height: 100%; }

.container__1zrm9 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.375rem 2.5rem 0 2.5rem; }
  @media screen and (max-width: 64rem) {
    .container__1zrm9 {
      background: white;
      padding: 0 0 0 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      z-index: 1;
      transform: translateY(calc(100% - 63px));
      transition: transform ease-in-out 320ms;
      will-change: transform; }
      .container__1zrm9.expand__1YWi6 {
        transform: translateY(0); } }
