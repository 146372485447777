.topContainer__15Cjv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  margin-top: 6.25rem; }
  @media screen and (max-width: 25.875rem) {
    .topContainer__15Cjv {
      margin-top: 0; } }

.headerTitle__9aKBV {
  margin-bottom: 3.625rem; }

.search__1vtQ_ {
  max-width: 37.5rem;
  margin-bottom: 13rem;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0 1.25rem; }
  @media screen and (max-width: 64rem) {
    .search__1vtQ_ {
      margin-bottom: 2.5rem; } }
  @media screen and (max-width: 25.875rem) {
    .search__1vtQ_ {
      margin-bottom: 3.75rem; } }

@media screen and (max-width: 25.875rem) {
  .searchResults__usV8R {
    left: 0;
    right: 0; } }

@media screen and (max-width: 25.875rem) {
  .searchNotifications__23ABR {
    left: 1.25rem;
    right: 1.25rem;
    width: auto; } }

.illustrationWrapper__1ZN-u {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3.75rem;
  width: 100%;
  order: 0; }
  @media screen and (max-width: 25.875rem) {
    .illustrationWrapper__1ZN-u {
      order: -1; } }

.illustrationContainer__3ZyIb {
  overflow: hidden;
  position: relative;
  width: 85.5rem;
  height: 17.875rem; }
  @media screen and (max-width: 25.875rem) {
    .illustrationContainer__3ZyIb {
      height: 12.5rem; } }

.illustration__5LVeX {
  position: absolute;
  bottom: 0;
  width: 85.5rem;
  height: auto; }
  @media screen and (max-width: 48rem) {
    .illustration__5LVeX {
      left: -9.375rem; } }
