.item__194YI {
  box-shadow: 0 1px 4px 0 rgba(79, 88, 122, 0.14);
  border-radius: 0.25rem;
  background-color: #ffffff;
  list-style: none; }

.link__28ZLP {
  display: flex;
  flex-direction: row;
  height: 4.375rem;
  text-decoration: none; }

.arrow__204Vh {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }
  .arrow__204Vh svg {
    height: 10px;
    stroke: #143268; }
  .arrow__204Vh div {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center; }
  .arrow__204Vh.isRead__3ruDg div {
    border: 2px solid rgba(0, 0, 0, 0.04); }

.categorySubcategoryBox__3Fog0 {
  padding: 1rem 0 1rem 1rem;
  width: 100%;
  flex: 1 1 auto;
  min-width: 0; }

.category__l2dDr {
  font-size: 1rem;
  font-weight: 500;
  color: #143268;
  letter-spacing: 0.07px;
  line-height: 19px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.subCategory__1md7- {
  font-size: 0.75rem;
  font-weight: 500;
  color: #838998;
  letter-spacing: 0.06px;
  line-height: 25px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.tgBox__AmU2u {
  height: 4.375rem;
  display: flex;
  position: relative;
  flex-direction: row;
  font-weight: 600; }

.tgValue__34Rr9 {
  color: #fff;
  width: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  flex: 1 0 auto; }
  .tgValue__34Rr9 span {
    font-size: 0.75rem;
    color: #ffffff;
    letter-spacing: 0.06px; }

.tgMismatch__2u3Ub {
  position: absolute;
  left: 3.75rem;
  top: 50%;
  transform: translate(-0.6rem, -0.6rem); }

.critical__2uW0m h3 {
  font-size: 1rem;
  color: #ffffff;
  letter-spacing: 0.07px;
  line-height: 19px; }

.critical__2uW0m h4 {
  opacity: 0.7;
  font-size: 0.75rem;
  color: #ffffff;
  letter-spacing: 0.06px;
  line-height: 25px; }

.critical__2uW0m .arrow__204Vh svg {
  height: 10px;
  stroke: #fff; }

.critical__2uW0m .arrow__204Vh div {
  border: none; }

.critical__2uW0m .arrow__204Vh.isRead__3ruDg div {
  background: rgba(255, 255, 255, 0.2); }

.chat__E4mfN {
  display: flex;
  align-items: center;
  padding-left: 1rem; }
  .chat__E4mfN.withPadding__3Xh2y {
    padding: 1rem; }
