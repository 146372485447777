.item__1Eu0F {
  box-shadow: 0 1px 4px 0 rgba(79, 88, 122, 0.14);
  border-radius: 0.25rem;
  background-color: #ffffff;
  list-style: none; }

.link__2OM5z {
  display: flex;
  flex-direction: row;
  height: 4.375rem;
  text-decoration: none; }

.categorySubcategoryBox__163Xo {
  padding: 1rem;
  align-self: center;
  width: 100%;
  flex: 1 1 auto;
  min-width: 0; }

.category__ex_59 {
  font-size: 1rem;
  font-weight: 500;
  color: #143268;
  letter-spacing: 0.07px;
  line-height: 19px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.tgBox__1DSLv {
  height: 4.375rem;
  display: flex;
  position: relative;
  flex-direction: row;
  font-weight: 600; }

.tgValue__3rA4K {
  color: #fff;
  width: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  flex: 1 0 auto; }
  .tgValue__3rA4K span {
    font-size: 0.75rem;
    color: #ffffff;
    letter-spacing: 0.06px; }

.tgMismatch__2r7yR {
  position: absolute;
  left: 3.75rem;
  top: 50%;
  transform: translate(-0.6rem, -0.6rem); }
