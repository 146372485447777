.button__34CL0 {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  background: #558efc;
  border-radius: 24.5px;
  min-width: 8.75rem;
  min-height: 2.875rem;
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #fff; }
  .button__34CL0 svg {
    margin-left: 0.375rem; }
