.container__11Q9x {
  background: #fff;
  box-shadow: 0 0.75rem 0.4375rem -0.5rem rgba(79, 88, 122, 0.06);
  border-radius: 0.25rem;
  padding: 2.25rem; }
  @media screen and (max-width: 64rem) {
    .container__11Q9x {
      padding: 1.5rem 1.25rem; } }

.title__2BUdT {
  font-weight: 600;
  font-size: 1.125rem;
  color: #3c4666;
  line-height: 1.5625rem;
  margin: 0 0 0.375rem 0;
  text-align: center;
  text-decoration: none; }
  @media screen and (max-width: 64rem) {
    .title__2BUdT {
      font-size: 1rem; } }

.subHeader__1VxF4 {
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: #acb2c7;
  line-height: 1.5625rem;
  margin: 0 0 1.375rem 0; }
  @media screen and (max-width: 64rem) {
    .subHeader__1VxF4 {
      font-size: 0.875rem; } }

.bar__1saWD {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 3.125rem; }

.tg__1UbiA {
  padding: 0 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  margin-right: 0.25rem; }
  .tg__1UbiA:last-child {
    margin-right: 0; }

.tg1__1Sa9X {
  background: #5dd371; }

.tg2__2yQaM {
  background: #ff9a63; }

.tg3__1rzLV {
  background: #fc5955; }
