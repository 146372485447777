.basicInfo__2T6yF {
  background: #fff;
  max-width: 48.4375rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 1px 4px 0 rgba(79, 88, 122, 0.14);
  border-radius: 2px; }

.container__2FxbA {
  display: flex; }

.col__1FDiw {
  padding: 2.1875rem;
  flex: 1 0 50%; }
  .col__1FDiw + .col__1FDiw {
    border-left: 1px solid #dee2ec; }

.item__37R_2 + .item__37R_2 {
  margin-top: 1.25rem; }

.item__37R_2 > span {
  font-weight: 600;
  font-size: 1.125rem;
  color: #3c4666;
  line-height: 1.5625rem;
  margin: 0 0 0.375rem 0;
  text-align: center;
  text-decoration: none;
  display: block;
  text-align: left; }
  @media screen and (max-width: 64rem) {
    .item__37R_2 > span {
      font-size: 1rem; } }

.header__UkKyZ {
  font-family: MaisonNeue-Demi;
  font-size: 1rem;
  color: #414b6a;
  line-height: 1.1875rem;
  margin: 0 0 0.3125rem 0; }

.infoItem__1kb3x {
  font-family: MaisonNeue-Demi;
  font-size: 0.875rem;
  color: #838998; }

.imageContainer__1wun9 {
  max-width: 100%;
  height: auto;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  overflow: hidden;
  max-height: 13.125rem; }

.image__3yd_1 {
  width: 100%;
  height: auto;
  vertical-align: middle; }
