.list_item__HqWXr {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(79, 88, 122, 0.1023); }

.photo__27R85 {
  padding: 0;
  margin: 0;
  height: 8.4375rem;
  width: 100%; }
  .photo__27R85 img,
  .photo__27R85 svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom; }

.address__EZAbG {
  text-align: center;
  color: #3c4666;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5625rem;
  color: #3c4666;
  margin-bottom: 0.5rem; }

.post__1yRkv {
  text-align: center;
  color: #3c4666;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-bottom: 1.125rem; }

.linkContent__2bmNJ {
  display: flex;
  flex-direction: column; }

.iconOverlay__3HK3A {
  position: relative;
  z-index: 1; }

.unread__PRmts,
.heart__VxIwQ,
.share__GwaZz {
  position: absolute;
  height: auto;
  cursor: pointer;
  top: 0.625rem;
  transition: opacity ease-in-out 160ms;
  will-change: opacity; }
  .unread__PRmts:hover,
  .heart__VxIwQ:hover,
  .share__GwaZz:hover {
    opacity: 0.85; }

.unread__PRmts {
  width: 5rem;
  height: 1.875rem;
  right: 0;
  display: flex;
  align-items: center;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  color: #fc5955;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }
  .unread__PRmts span,
  .unread__PRmts div {
    font-weight: 600;
    letter-spacing: 0.0674603px; }
  .unread__PRmts .circle__3NdMM {
    margin-left: 0.125rem;
    position: relative;
    color: white;
    width: 1.0625rem;
    height: 1.0625rem; }
    .unread__PRmts .circle__3NdMM span {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 0.625rem;
      line-height: 1.0625rem; }
    .unread__PRmts .circle__3NdMM svg {
      width: 100%;
      height: auto; }
  .unread__PRmts .ttext__2F5c4 {
    font-size: 14px;
    margin-left: 0.1875rem; }

.share__GwaZz {
  width: 1.875rem;
  left: 0.625rem; }

.heart__VxIwQ {
  width: 1.875rem;
  left: 3.125rem; }

.favouriteContainer__u-qeX {
  padding: 0;
  margin-left: 1.375rem;
  margin-right: 1.375rem;
  margin-bottom: 1.875rem; }
  .favouriteContainer__u-qeX h3 {
    font-size: 0.75rem;
    line-height: 1.5625rem; }

.propertyPlaceholder__Yt1Ss {
  width: 100%;
  height: 8.4375rem;
  background: #e0e7f7;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .propertyPlaceholder__Yt1Ss svg {
    height: auto;
    width: 3.75rem; }
